import { ImageProps } from 'next/image'
import * as S from './styles'

const Svg = ({ className, ...rest }: ImageProps & { className?: string }) => {
  return (
    <S.SvgContainer {...(className && { className })}>
      <S.Svg {...rest} />
    </S.SvgContainer>
  )
}

export default Svg
