import { theme } from '@provi/provi-components'
import { useMemo } from 'react'
import { IPaymentForms } from 'types'

interface IPaymentMethodIcon {
  paymentMethod: IPaymentForms
}

const PaymentMethodIcon = ({ paymentMethod }: IPaymentMethodIcon) => {
  const renderPaymentMethodIcon = useMemo(() => {
    switch (paymentMethod) {
      case 'CreditCard':
        return (
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path
              d="M24 24.0001H29M16 24.0001H19M2.00073 13H33.9998M3.23073 7.00012H32.7684C33.4481 7.00012 33.9991 7.54733 33.9991 8.22234V27.7779C33.9991 28.4529 33.4481 29.0001 32.7684 29.0001H3.23073C2.55102 29.0001 2 28.4529 2 27.7779V8.22234C2 7.54733 2.55102 7.00012 3.23073 7.00012Z"
              stroke={theme.colors.bluePrimary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )

      case 'Boleto':
        return (
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path
              d="M27 4.99963L34 4.99999V12M9 31H2V23.9996M34 23.9996V31H27M2 12V4.99999L9 4.99963M10 10.9996V24.9996M26 10.9996V24.9996M21 10.9996V24.9996M15 10.9996V24.9996"
              stroke={theme.colors.bluePrimary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )

      case 'Pix':
        return (
          <svg aria-label="Pix" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 512 512">
            <path
              stroke={theme.colors.bluePrimary}
              fill={theme.colors.bluePrimary}
              className="pix-icon"
              d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"
            />
          </svg>
        )

      default:
        return (
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path
              d="M30.9742 22.1435C30.0642 22.4969 28.2105 23.5569 27.4353 24.1223C26.6601 24.6876 25.3794 25.0763 23.7279 25.3943C23.3572 25.465 22.919 25.5003 22.4472 25.5356C21.3012 25.571 19.9531 25.4296 18.4701 25.3236C18.369 25.3236 18.2679 25.2883 18.1668 25.2883C17.7961 25.253 17.4927 24.9703 17.459 24.5816C17.4253 24.0869 17.7961 23.6983 18.2342 23.7689C18.3353 23.7689 18.4701 23.8043 18.5713 23.8043C20.4924 23.9809 22.3124 24.1576 23.4583 23.9103C25.5816 23.4863 26.1883 22.1435 26.0534 21.0129C25.9523 20.0942 25.2783 19.2108 24.2335 19.2815C22.7505 19.3875 20.9979 19.2815 19.279 19.1401C17.3242 18.9988 15.3357 18.9281 13.9202 19.1048C12.3361 19.2815 9.13426 20.5182 6.30316 22.5322C4.92131 23.5216 3.37095 24.6876 2.42725 25.4296C1.9554 25.783 1.85429 26.4897 2.22503 26.9843L6.33686 32.5672C6.6739 33.0618 7.34797 33.1325 7.81982 32.7792L9.33648 31.5425C9.70722 31.2598 10.1791 31.1538 10.5835 31.2951C13.3472 32.2138 16.3131 32.8145 18.2342 32.9205C22.4472 33.1325 30.5697 27.903 32.8953 26.207C35.2208 24.5463 33.5356 21.1189 30.9742 22.1435Z"
              stroke={theme.colors.bluePrimary}
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.5 15C24.0899 15 27 12.0899 27 8.5C27 4.91015 24.0899 2 20.5 2C16.9101 2 14 4.91015 14 8.5C14 12.0899 16.9101 15 20.5 15ZM19.8668 5.03484V4.15679C19.8668 4.06272 19.9339 4 20.0346 4H20.9409C21.0416 4 21.1087 4.06272 21.1087 4.15679V5.00348C22.0485 5.0662 22.8205 5.72474 22.8876 6.60279C22.8876 6.69687 22.8205 6.79094 22.7198 6.79094H21.78C21.7128 6.79094 21.6122 6.72822 21.6122 6.66551C21.545 6.38328 21.3101 6.19512 20.9744 6.19512H20.1689C19.699 6.19512 19.2626 6.50871 19.1955 6.94774C19.1284 7.44948 19.5647 7.8885 20.1017 7.8885H20.8066C22.0821 7.8885 23.1226 8.92335 22.9883 10.1463C22.8876 11.0871 22.0821 11.8084 21.0751 11.9652V12.8432C21.0751 12.9373 21.008 13 20.9073 13H20.0682C19.9675 13 19.9004 12.9373 19.9004 12.8432V11.9965C18.9605 11.9338 18.1885 11.2753 18.1214 10.3972C18.1214 10.3031 18.1885 10.2091 18.2892 10.2091H19.2291C19.2962 10.2091 19.3969 10.2718 19.3969 10.3345C19.464 10.6167 19.699 10.8049 20.0346 10.8049H20.8402C21.3101 10.8049 21.7464 10.4913 21.8135 10.0209C21.8807 9.51916 21.4443 9.08014 20.9073 9.08014H20.3031C19.1619 9.08014 18.1214 8.29617 18.0207 7.22997C17.8529 6.1324 18.7256 5.16028 19.8668 5.03484Z"
              fill={theme.colors.bluePrimary}
            />
          </svg>
        )
    }
  }, [paymentMethod])

  return renderPaymentMethodIcon
}

export default PaymentMethodIcon
