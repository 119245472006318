import { ButtonV3 as Button } from '@provi/provi-components'
import { memo, useMemo, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  CartItem,
  CartReview,
  CourseFinancingDateReview,
  CourseFinancingSelector,
  InformationTextWithTooltip,
} from '~/components/molecules'

import { IItem } from 'types'
import { whatIsIOFText } from './constants'
import { useCourseFinancingForm } from './hooks'
import * as S from './style'

interface ICourseFinancingForm {
  firstName?: string
  hideSubmit?: boolean
}

const CourseFinancingForm = ({ firstName, hideSubmit }: ICourseFinancingForm) => {
  const {
    courseFinancingData,
    selectedPartnerConditionId,
    handleSelectedPartnerConditionId,
    handleSubmit,
    isInCourseFinancingReviewScreen,
    isSubmitDisabled,
    cartData,
    onRecaptchaChange,
    isCourseFinancingLoading,
    formatedCourseFinancingIOF,
    isSendingData,
    isPaymentInfoLoading,
    userFirstName,
  } = useCourseFinancingForm()

  const recaptchaRef = useRef(null)

  const renderCourseFinancingSelectionScreen = useMemo(() => {
    if ((isCourseFinancingLoading || isPaymentInfoLoading) && process.env.NODE_ENV !== 'test') {
      return (
        <>
          <S.CourseFinancingFormTextLoading />

          <S.CourseFinancingSelectorLoadingWrapper>
            <S.CourseFinancingSelectorLoading />
            <S.CourseFinancingSelectorLoading />
            <S.CourseFinancingSelectorLoading />
            <S.CourseFinancingSelectorLoading />
          </S.CourseFinancingSelectorLoadingWrapper>

          <S.CourseFinancingFormTextLoading />
        </>
      )
    }

    return (
      <>
        <S.CourseFinancingFormText>
          {userFirstName || firstName || 'Estudante'}, aqui estão as condições de pagamento que preparamos para você!
        </S.CourseFinancingFormText>

        <CourseFinancingSelector
          data={courseFinancingData}
          selectedPartnerConditionId={selectedPartnerConditionId}
          handleSelectedPartnerConditionId={handleSelectedPartnerConditionId}
        />
        <S.CourseFinancingFormText>
          O valor das parcelas já inclui todos os juros do período e as taxas de IOF fixo e diário.
        </S.CourseFinancingFormText>
        <div className="flex space-x-1 items-center">
          <small className="text-sm align-top text-brand-primaryLight">&#42;</small>
          <InformationTextWithTooltip
            text="Informações importantes"
            tooltipText={whatIsIOFText(formatedCourseFinancingIOF())}
          />
        </div>
      </>
    )
  }, [
    isCourseFinancingLoading,
    courseFinancingData,
    selectedPartnerConditionId,
    handleSelectedPartnerConditionId,
    isPaymentInfoLoading,
    formatedCourseFinancingIOF,
    userFirstName,
    firstName,
  ])

  const renderCourseFinancingReview = useMemo(() => {
    return (
      <>
        <S.CourseFinancingCartItems>
          {cartData?.courses?.map((item: IItem) => {
            return <CartItem key={item.CourseClassId} item={item} isReadOnly={true} />
          })}
        </S.CourseFinancingCartItems>

        <CartReview shouldUseCartData={false} />
        <CourseFinancingDateReview />
      </>
    )
  }, [cartData?.courses])

  return (
    <S.CourseFinancingFormWrapper isInCourseFinancingReviewScreen={isInCourseFinancingReviewScreen}>
      {isInCourseFinancingReviewScreen ? renderCourseFinancingReview : renderCourseFinancingSelectionScreen}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        hl="pt-BR"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_V3}
        onChange={onRecaptchaChange}
      />

      {/* fallback if user might be a robot */}
      {isSubmitDisabled && isInCourseFinancingReviewScreen && (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="small"
          hl="pt-BR"
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_V2}
          onChange={onRecaptchaChange}
        />
      )}
      {!hideSubmit && (
        <S.ButtonWrapper>
          <Button
            text="Continuar"
            type="submit"
            icon={true}
            mobileWidth="100%"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
            isLoading={isSendingData}
          />
        </S.ButtonWrapper>
      )}
    </S.CourseFinancingFormWrapper>
  )
}

export default memo(CourseFinancingForm)
