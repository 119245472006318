import { Popover } from '@material-ui/core'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { IPaymentForms } from 'types'
import { PaymentMethodIcon, Svg } from '~/components/atoms'
import * as S from './style'

interface IPaymentMethodCard {
  paymentMethod: IPaymentForms
  isActive: boolean
  isDisabled: boolean
  isLoading: boolean
  reason?: string
  onClick: () => void
}

const PaymentMethodCard = ({ paymentMethod, isActive, isDisabled, isLoading, reason, onClick }: IPaymentMethodCard) => {
  const handleClick = useCallback(() => {
    if (isDisabled) {
      toast.error(reason || 'Meio de pagamento indisponível')
    } else {
      onClick()
    }
  }, [isDisabled, onClick, reason])

  const renderName = useMemo(() => {
    switch (paymentMethod) {
      case 'CreditCard':
        return 'Cartão de crédito'
      case 'Boleto':
        return 'Boleto à vista'
      case 'Pix':
        return 'Pix'
      default:
        return 'Boleto parcelado'
    }
  }, [paymentMethod])

  const renderPaymentMethodCardLoading = useMemo(() => {
    return (
      <S.LoadingCardWrapper isActive={false} isDisabled={false} as="div">
        <S.LoadingPaymentMethodIcon />
        <S.LoadingCardTitle />
      </S.LoadingCardWrapper>
    )
  }, [])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isDisabled) {
        setAnchorEl(event.currentTarget)

        setTimeout(() => {
          setAnchorEl(null)
        }, 5000)
      }
    },
    [isDisabled, setAnchorEl],
  )

  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const renderCard = useMemo(() => {
    return (
      <>
        <S.CardWrapper
          isActive={isActive}
          isDisabled={isDisabled}
          onClick={handleClick}
          title={isDisabled ? (reason ? reason : '') : renderName}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
        >
          <PaymentMethodIcon paymentMethod={paymentMethod} />
          {isDisabled ? (
            <Svg
              src="/assets/svg/info.svg"
              alt="mais informações"
              width={24}
              height={24}
              className="absolute top-1 right-1 md:top-2 md:right-2 opacity-50"
            />
          ) : null}
          <S.CardTitle isActive={isActive} isDisabled={isDisabled}>
            {renderName}
          </S.CardTitle>
        </S.CardWrapper>
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: 'px-2 py-4',
          }}
          style={{ padding: 24 }}
          onClose={handlePopoverClose}
          // disableRestoreFocus
          disableScrollLock
        >
          <p>{reason}</p>
        </Popover>
      </>
    )
  }, [
    renderName,
    isActive,
    isDisabled,
    handleClick,
    reason,
    paymentMethod,
    open,
    anchorEl,
    handlePopoverOpen,
    handlePopoverClose,
  ])

  if (isLoading && process.env.NODE_ENV !== 'test') {
    return renderPaymentMethodCardLoading
  }

  return renderCard
}

export default PaymentMethodCard
